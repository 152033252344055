import styled from "styled-components";
import {
  BP,
  Rem,
} from "../../commons/Theme";

export const StyledPressScroller = styled.div`
  padding-top: ${Rem(40)};

  @media (${BP.ipad}) {
    padding-top: ${Rem(94)};
    padding-bottom: ${Rem(40)};
  }
`;

export const StyledPressScrollerItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const StyledPressScrollerItem = styled.div`
  position: relative;
  min-width: 80vw;
  margin-left: ${Rem(20)};

  @media (${BP.ipad}) {
    min-width: ${Rem(396)};
  }
`;
